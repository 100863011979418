<template>
  <div class="product-intro__size">
    <div
      class="product-intro__size-title"
      tabindex="0"
      :aria-label="language.SHEIN_KEY_PC_21859"
    >
      {{ language.SHEIN_KEY_PC_21859 }}
    </div>
    <div 
      v-expose="getAllData('1-8-6-95')" 
      class="product-intro__size-choose"
    >
      <div
        v-for="item in mallList"
        :key="item.mall_code"
        v-tap="getData('1-8-6-96', item.mall_code)"
        v-enterkey
        class="product-intro__size-mall_item"
        :class="{
          'product-intro__size-radio': true,
          'product-intro__size-radio_active':
            item.mall_code == mallCode &&
            chooseCheck(-1, '', '', item.mall_code) >= 0,
          'product-intro__size-radio_soldout':
            !isOnSale || chooseCheck(-1, '', '', item.mall_code) === 0,
        }"
        tabindex="0"
        :aria-label="item.mall_name"
        @click="mallClick(item.mall_code)"
      >
        <div class="product-intro__size-radio-inner">
          {{ item.mall_name }}
        </div>
      </div>
    </div>
    <div 
      v-if="tips" 
      class="product-intro__sizes-malltip"
    >
      <Icon
        class="icon"
        name="sui_icon_lightbulb_24"
        size="24px"
      />
      <div v-html="tips"></div>
    </div>
  </div>
</template>

<script>
import { template } from '@shein/common-function'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  directives: {
    tap,
    expose,
  },
  components: {
    Icon
  },
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    mallCode: {
      type: String,
      default: '',
    },
    isOnSale: {
      type: Boolean,
      default: false,
    },
    chooseCheck: {
      type: Function,
      default: () => new Function(),
    },
    from: {
      type: String,
      default: '',
    },
    siteUid: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    mallList() {
      return this.detail.mall_info
    },
    location() {
      return this.from == 'detail' ? 'detail' : 'popup'
    },
    mallInfo() {
      return this.mallList.find(_ => _.mall_code == this.mallCode) || {}
    },
    tips() {
      const { language, mallInfo, siteUid, mallCode, config } = this
      let TIPS = ''
      if (!!~siteUid.indexOf('us')) {
        TIPS = language.SHEIN_KEY_PC_23187
      } else if (!!~siteUid.indexOf('de')) {
        // 德国
        TIPS = language.SHEIN_KEY_PC_23188
      } else if (!!~siteUid.indexOf('fr')) {
        // 法国
        TIPS = language.SHEIN_KEY_PC_23189
      } else {
        TIPS = language.SHEIN_KEY_PC_21953
      }
      if (mallInfo.mall_name) {
        // 巴西 特殊处理
        if (!!~siteUid.indexOf('br') && mallCode == '1' && config?.showTaxation) {
          return `<p>${language.SHEIN_KEY_PC_27331}</p>`
        }
        return this.template(`<b>${mallInfo.mall_name}</b>`, TIPS)
      } else {
        return ''
      }
    },
  },
  methods: {
    template,
    mallClick(mallCode) {
      const mallStock = this.chooseCheck(-1, '', '', mallCode)
      this.$emit('mallClick', {
        mallCode,
        mallStock,
      })
    },
    getAllData(id) {
      return {
        id,
        data: {
          location: this.location,
          mall_code: this.mallList.map(_ => _.mall_code).join('`'),
        },
      }
    },
    getData(id, mallCode) {
      return {
        id,
        data: {
          location: this.location,
          mall_code: mallCode,
        },
      }
    },
  },
  emits: ['mallClick'],
}
</script>

<style lang="less">
.product-intro {
  &__size-mall_item {
    margin-right: 12px;
  }
  &__sizes-malltip {
    .flexbox();
    .align-center();
    .space-between();
    padding: 12px 10px 12px 14px;
    margin: 12px 0 20px 0;
    background: #f7f8fa;
    color: #666;
    font-size: 12px;
    line-height: 1.2;
    svg {
      font-size: 20px;
      color: @sui_color_warning;
      margin-right: 14px;
    }
  }
}
</style>

function getAttrListFromSizeInfoDes(skcSaleAttr, sizeInfoDes) {
  const isMultiPart = sizeInfoDes.multiPartFlag
  let sizeVal = ''
  if (isMultiPart) {
    const multiPartInfo = sizeInfoDes.multiPartInfo || []
    for (let i = 0; i < multiPartInfo.length; i++) {
      if (multiPartInfo[i]?.multiPartSizeInfo?.length) {
        const sizeInfoOne = multiPartInfo[i].multiPartSizeInfo.find(_ => (_.attr_value_name || _.size))
        if (sizeInfoOne) {
          sizeVal = sizeInfoOne.attr_value_name || sizeInfoOne.size
          break
        }
      }
    }
  } else {
    let sizeInfo = []
    if (sizeInfoDes?.sizeInfo?.length) {
      sizeInfo = sizeInfoDes.sizeInfo
    } else if (sizeInfoDes?.basicAttribute?.base_code_info?.length) {
      sizeInfo = sizeInfoDes.basicAttribute.base_code_info
    } else if (sizeInfoDes?.basicAttribute?.base_code_info_other?.length) {
      sizeInfo = sizeInfoDes.basicAttribute.base_code_info_other
    }
    const sizeInfoOne = sizeInfo.find(_ => (_.attr_value_name || _.size))
    if (sizeInfoOne) {
      sizeVal = sizeInfoOne.attr_value_name || sizeInfoOne.size
    }
  }
  for (let i = 0; i < skcSaleAttr.length; i++) {
    if (skcSaleAttr[i]?.attr_value_list?.length) {
      const matchVal = skcSaleAttr[i].attr_value_list.find(_ => _.attr_value_name == sizeVal)
      if (matchVal) {
        return {
          attrList: skcSaleAttr[i].attr_value_list,
          sizeInfoDesAttrId: skcSaleAttr[i].attr_id,
          sizeInfoDesAttrName: skcSaleAttr[i].attr_name
        }
      }
    }
  }
  return {
    attrList: [],
    sizeInfoDesAttrId: '',
    sizeInfoDesAttrName: ''
  }
}
function formatSizeInfo(skcSaleAttr, sizeInfoDes) {
  const isMultiPart = sizeInfoDes.multiPartFlag
  const { attrList = [], sizeInfoDesAttrId = '', sizeInfoDesAttrName = '' } = getAttrListFromSizeInfoDes(skcSaleAttr, sizeInfoDes)
  const multiPartInfo = sizeInfoDes.multiPartInfo
  let normalCmOrg = sizeInfoDes.sizeInfo || []
  let normalInchOrg = sizeInfoDes.sizeInfoInch || []
  let baseCmOrg = sizeInfoDes.basicAttribute?.base_code_info || []
  let baseInchOrg = sizeInfoDes.basicAttribute?.base_code_info_inch || []
  let baseOtherOrg = sizeInfoDes.basicAttribute?.base_code_info_other || []
  const normalCm = []
  const normalInch = []
  const baseCm = [{ list: [] }]
  const baseInch = [{ list: [] }]
  const baseOther = [{ list: [] }]

  // sizeInfo的顺序和attrList可能不一样，还有缺失的数据，少的补空
  const len = attrList.length || 1
  for (let j = 0; j < (sizeInfoDes.multiPartInfo?.length || 1); j++) {
    for (let i = 0; i < len; i++) {
      const size = attrList[i]?.attr_value_name || ''
      const name = isMultiPart ? multiPartInfo[j].multiPartName : ''
      const code = isMultiPart ? multiPartInfo[j].multiPartCode : ''
      if (!normalCm[j]) normalCm[j] = { name, code, list: [] }
      if (!normalInch[j]) normalInch[j] = { name, code, list: [] }
      const sizeInfo = isMultiPart ? (multiPartInfo[j].multiPartSizeInfo || []) : normalCmOrg
      const sizeInfoInch = isMultiPart ? (multiPartInfo[j].multiPartSizeInfoInch || []) : normalInchOrg

      normalCm[j].list.push(sizeInfo.find(_ => (_.attr_value_name || _.size || '') == size) || {})
      normalInch[j].list.push(sizeInfoInch.find(_ => (_.attr_value_name || _.size || '') == size) || {})
      if (j === 0) {
        if (baseCmOrg.length) {
          baseCm[j].list.push(baseCmOrg.find(_ => (_.attr_value_name || _.size || '') == size) || {})
        }
        if (baseInchOrg.length) {
          baseInch[j].list.push(baseInchOrg.find(_ => (_.attr_value_name || _.size || '') == size) || {})
        }
        if (baseOtherOrg.length) {
          baseOther[j].list.push(baseOtherOrg.find(_ => (_.attr_value_name || _.size || '') == size) || {})
        }
      }
    }
  }
  return {
    normalCm,
    normalInch,
    baseCm,
    baseInch,
    baseOther,
    sizeInfoDesAttrId,
    sizeInfoDesAttrName,
    sizeInfoDesAttrList: attrList
  }
}


// 优先展示基码
function isBaseCodePriorityComplete (skcSaleAttr, sizeInfoDes) {
  let mode = sizeInfoDes.basicAttribute?.base_code_show_mode
  if (mode === 1) return true
  if (mode === 2) return false
  if (mode === 3) {
    const { normalCm } = formatSizeInfo(skcSaleAttr, sizeInfoDes)
    const normalCmOrg = normalCm[0].list || []
    const baseCmOrg = sizeInfoDes.basicAttribute?.base_code_info || []
    const baseOtherOrg = sizeInfoDes.basicAttribute?.base_code_info_other || []
    return (baseCmOrg.length !== 0 || baseOtherOrg.length !== 0) && (normalCmOrg.length == baseCmOrg.length || baseOtherOrg.length == normalCmOrg.length)
  }
  return false
}

/**
 * 获取尺码优先展示模式
 * @description pdc 的 mode 配置 0 或 3 时，且 sizepriority 不为 off 时，走abt逻辑
 * @description 0: 基码＋商品尺码，商品尺码优先
 * @description 1: 只有基码，没有商品尺码
 * @description 2: 只有商品尺码，没有基码
 * @description 3: 基码＋商品尺码，基码优先，但基码缺数据则全部展示商品尺码
 * @description 4: 不展示尺码信息
 * @param {number} mode 尺码优先展示模式（pdc配置）
 * @param {string} sizepriority 尺码优先级abt配置
 * @returns 
 */
function getSizePriorityMode (mode = 0, sizepriority = 'off') {
  if((mode === 0 || mode === 3) && sizepriority !== 'off') {
    if(sizepriority === 'product_size_first') {
      return 0
    }
    if(sizepriority === 'body_size_first') {
      return 3
    }
  }
  return mode
}

/**
 * 判断基码是否完整
 * @param {Object} sizeOrg 尺码表 { normalCmOrg: [], baseCmOrg: [], baseOtherOrg: [] } , 用于判断基码是否完整
 * @returns 
 */
function isBaseSizeComplete (sizeOrg = {}) {
  const { normalCmOrg, baseCmOrg, baseOtherOrg } = sizeOrg
  return (baseCmOrg.length !== 0 || baseOtherOrg.length !== 0) && (normalCmOrg.length == baseCmOrg.length || baseOtherOrg.length == normalCmOrg.length)
}

/**
 * 比较尺码优先展示级，返回最优先展示尺码
 * @description normal: 商品尺码优先
 * @description base: 基码优先
 * @description none: 不展示尺码信息
 * @param {number} sizePriorityMode 尺码优先展示模式（pdc配置）
 * @param {boolean} isBaseSizeComplete 基码是否完整
 * @param {string} sizepriority 尺码优先级abt配置
 * @returns 
 */
function compareSizePriority (sizePriorityMode = 0, isBaseSizeComplete, sizepriority = 'off') {
  let mode = getSizePriorityMode(sizePriorityMode, sizepriority)
  // 商品尺码优先
  if(mode === 0 || mode === 2) {
    return 'normal'
  }else if(mode === 1) {
    // 只有基码，没有商品尺码
    return 'base'
  }else if(mode === 3) {
    // 基码＋商品尺码，基码优先，但基码缺数据则全部展示商品尺码
    return isBaseSizeComplete ? 'base' : 'normal'
  } else {
    // 不展示尺码信息
    return 'none'
  }
}

/**
 * 获取优先展示尺码
 * @param {*} skcSaleAttr 
 * @param {*} sizeInfoDes 
 * @param {string} sizepriority 尺码优先级abt配置
 * @returns 
 */
function getPrioritySize (skcSaleAttr, sizeInfoDes, sizepriority = 'off') { 
  const {
    normalCm,
  } = formatSizeInfo(skcSaleAttr, sizeInfoDes)
  // 基码是否完整
  const isCompleted = isBaseSizeComplete({
    normalCmOrg: normalCm[0].list || [],
    baseCmOrg: sizeInfoDes.basicAttribute?.base_code_info || [],
    baseOtherOrg: sizeInfoDes.basicAttribute?.base_code_info_other || []
  })
  // 优先展示尺码
  let sizePriority = compareSizePriority(sizeInfoDes.basicAttribute?.base_code_show_mode, isCompleted, sizepriority)
  return sizePriority
}


function getBubbleInfo (skcSaleAttr, sizeInfoDes, tackInfoDes, language, sizepriority = 'off') {
  
  let bubbleInfo = {
    cm: [],
    inch: []
  }
  const {
    normalCm,
    normalInch,
    baseCm,
    baseInch,
    baseOther,
    sizeInfoDesAttrId,
    sizeInfoDesAttrList
  } = formatSizeInfo(skcSaleAttr, sizeInfoDes)
  // 基码是否完整
  const isCompleted = isBaseSizeComplete({
    normalCmOrg: normalCm[0].list || [],
    baseCmOrg: sizeInfoDes.basicAttribute?.base_code_info || [],
    baseOtherOrg: sizeInfoDes.basicAttribute?.base_code_info_other || []
  })
  // 优先展示尺码
  let sizePriority = compareSizePriority(sizeInfoDes.basicAttribute?.base_code_show_mode, isCompleted, sizepriority)

  if(sizePriority === 'normal') {
    bubbleInfo = getSizeInfoForBubble(tackInfoDes, {
      inch: normalInch,
      cm: normalCm
    })
    bubbleInfo.title = language.SHEIN_KEY_PC_19565
  } else if(sizePriority === 'base') {
    bubbleInfo = getSizeInfoForBubble(tackInfoDes, {
      inch: baseInch,
      cm: baseCm,
      other: baseOther
    })
    bubbleInfo.title = language.SHEIN_KEY_PC_19497
  }
  
  bubbleInfo.sizeInfoDesAttrId = sizeInfoDesAttrId
  bubbleInfo.sizeInfoDesAttrList = sizeInfoDesAttrList
  return bubbleInfo
}

function getSizeInfoForBubble (tackInfoDes, { inch = [], cm = [], other = [] }) {
  if (cm.length == 1 && !cm[0].name) {
    const len = Math.max(cm[0].list.length, other?.[0]?.list?.length || 0)
    for (let i = 0; i < len; i++) {
      inch[0].list[i] = Object.assign({}, inch[0].list[i] || {}, other?.[0]?.list?.[i] || {}, tackInfoDes[i] || {})
      cm[0].list[i] = Object.assign({}, cm[0].list[i] || {}, other?.[0]?.list?.[i] || {}, tackInfoDes[i] || {})
    }
  }
  return {
    inch,
    cm
  }
}

function getFitPercentageRoundNum(text) {
  if (typeof text !== 'string' || !text) {
    return 0
  }
  const num = text.replace('%', '')
  if (isNaN(num)) {
    return 0
  }
  return Math.round(num)
}

/**
 * 对主副尺码进行排序
 * @param {string} defaultSize 默认尺码
 * @param {string} localSize 本地尺码
 * @param {string} defaultlocalsize abt配置
 * @returns 
 */
function sortMainSubSize (defaultSize, localSize, defaultlocalsize) {
  // 主尺码，即在前面的尺码
  let main_size = localSize ? localSize : defaultSize
  // 副尺码，即括号内的尺码 (不存在本地尺码为副尺码的情况)
  let sub_size = ''
  if (defaultlocalsize === 'old' || defaultlocalsize === 'new') {
    sub_size = localSize ? `(${defaultSize})` : ''
  }else { 
    // 命中 default_size_no_default 或 local_size_no_default 时，不展示副尺码
    sub_size =  ''
  }
  return {
    main_size,
    sub_size,
    size: sub_size ? `${main_size} ${sub_size}` : main_size
  }
}

export {
  getBubbleInfo,
  formatSizeInfo,
  getFitPercentageRoundNum,
  isBaseCodePriorityComplete,
  getPrioritySize,
  sortMainSubSize
}

<template>
  <div
    v-if="shouldShow"
    class="size-feedback"
  >
    <p class="size-feedback_title">
      <!-- Not your size?  -->
      {{ language.SHEIN_KEY_PC_22268 }}
      <!-- Tell me your size -->
      <span @click="onSizeFeedbackBtnClick">
        {{
          language.SHEIN_KEY_PC_22259
        }}
      </span>
    </p>

    <!-- 弹窗 -->
    <ClientOnly>
      <s-dialog
        v-if="renderFeedbackDialog"
        v-model:visible="dialogShow"
        :type="'W720'"
        :show-close="true"
        :append-to-body="true"
        :class="{
          'size-feedback_dialog': true,
          'ready-state': status === 'ready',
          'success-state': status === 'success',
        }"
        :observe-body-height="false"
        @closed="onClose"
      >
        <template #title>
          <template v-if="status === 'ready'">
            <!-- Tell me your size -->
            {{ language.SHEIN_KEY_PC_22259 }}
          </template>
          <span v-else></span>
        </template>

        <template v-if="status === 'ready'">
          <div class="size-feedback_body">
            <!-- 尺码选择面板 -->
            <div class="tips-band">
              <!-- We will optimize the size range based on your feedback. -->
              {{ language.SHEIN_KEY_PC_22260 }}
            </div>
            <div class="local-bar">
              <div class="local-bar__container">
                <!-- Size -->
                {{ language.SHEIN_KEY_PC_14657 }}
                <div
                  v-if="hasMultiLocal"
                  class="local-bar__select"
                >
                  <CommonLocalSizeSelect
                    v-model:value="selectedLocation"
                    :multi-local-size="multiLocalSize"
                    :language="language"
                    :style="{ backgroundColor: '#F6F6F6', border: 'none', marginLeft: '5px' }"
                    :default-text="language.SHEIN_KEY_PC_24641"
                    :is-new-size-local="isNewSizeLocal"
                    @update:value="onChangeSelectCountry"
                  />
                </div>
              </div>
              <!-- 尺码选项容器 -->
              <ul :class="{ 'size-option_box': true, romwe: isRw }">
                <li
                  v-for="(size, index) in feedBackSizeList"
                  :key="index"
                  :class="{ active: size.key === selectedSize.key }"
                  @click.stop="selectSize(size)"
                >
                  <div class="option-text">
                    {{ size.text }}
                  </div>
                </li>
              </ul>
            </div>
            <div class="size-feedback_footer">
              <s-button
                :type="['primary']"
                :disabled="!selectedSize.key"
                @click="handleSubmit"
              >
                <!-- SUBMIT -->
                {{ language.SHEIN_KEY_PC_22300 }}
              </s-button>
            </div>
          </div>
        </template>

        <!-- 提交成功面板 -->
        <div
          v-if="status === 'success'"
          class="size-feedback_success-box"
        >
          <Icon
            :class="{
              romwe: isRw,
            }"
            name="sui_icon_success_96px"
            size="96px"
          />
          <!-- Submit successful! -->
          <h2>{{ language.SHEIN_KEY_PC_22263 }}</h2>
          <!-- Thank you for your feedback. -->
          <h3 v-show="selectedSize.type === 1">
            {{ language.SHEIN_KEY_PC_22264 }}
          </h3>
          <!-- We also have found similar items for you in size -->
          <p v-show="selectedSize.type === 0">
            {{ similarRecommendText }}
          </p>
          <s-button
            v-show="selectedSize.type === 0"
            :type="['default', 'H44PX']"
            @click="handleShowSimilar"
          >
            <!-- SUBMIT -->
            {{ language.SHEIN_KEY_PC_22293 }}
          </s-button>
        </div>
      </s-dialog>
    </ClientOnly>
  </div>
</template>

<script>
import { OriginSizes } from '../constants'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { template } from '@shein/common-function'
import CommonLocalSizeSelect from './CommonLocalSizeSelect'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { mapActions } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'
import { sortMainSubSize } from 'public/src/pages/goods_detail_v2/utils/sizeInfoDesc.js'

let SimilarPopover = {}

if (typeof window !== 'undefined') {
  import('public/src/pages/common/similar_products').then(res => {
    SimilarPopover = res.default
  })
}

export default {
  components: {
    CommonLocalSizeSelect,
    ClientOnly,
    Icon
  },
  props: {
    currentSizeList: {
      type: Array,
      default: () => []
    },
    country: {
      type: String,
      default: ''
    },
    isNewSizeLocal: {
      type: Boolean,
      default: false
    },
    productIntroData: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    isRw: {
      type: Boolean,
      default: false
    },
    defaultlocalsize: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      renderFeedbackDialog: false,
      dialogShow: false,
      selectedLocation: '',
      /**
       * ready: 待提交 | success: 提交成功
       */
      status: 'ready',
      showLocalSize: false,
      selectedSize: {
        key: '',
        type: -1
      },
      showSimilarFlag: false,
      /**
       * 实验类型
       * * 默认不展示
       */
      countryOptionOpen: false
    }
  },
  computed: {
    goodsDetail() {
      const { productIntroData } = this
      return productIntroData?.detail || {}
    },
    multiLocalSize() {
      const { productIntroData } = this
      return (
        (productIntroData.multiLocalSize &&
          productIntroData.multiLocalSize.size_rule_list) ||
        {}
      )
    },
    // 尺码反馈是否需要展示
    shouldShow() {
      return this.feedbackBasicSizes.length
    },
    hasOneLocalSize() {
      const { currentSizeList, multiLocalSize } = this
      if (currentSizeList && multiLocalSize) {
        for (const attrSizeItem of currentSizeList) {
          for (const i in multiLocalSize) {
            for (let j = 0; j < multiLocalSize[i].length; j++) {
              if (attrSizeItem.attr_value_name === multiLocalSize[i][j].name) {
                return true
              }
            }
          }
        }
        return false
      }
      return false
    },
    // 地区选择框是否展示
    hasMultiLocal() {
      const { multiLocalSize, hasOneLocalSize } = this
      if (
        !multiLocalSize ||
        !Object.keys(multiLocalSize).length ||
        !hasOneLocalSize
      ) {
        return false
      }
      return true
    },
    // 当前选择地区是否有尺码数据
    hasLocalSizeOptions() {
      const { multiLocalSize, selectedLocation } = this
      return !!multiLocalSize[selectedLocation]
    },
    // 尺码和数值对照
    localSizeRuleMap() {
      const { multiLocalSize, selectedLocation } = this
      const sizeRules = multiLocalSize || {}
      const localSizeRule = sizeRules[selectedLocation] || []
      const result = new Map()
      localSizeRule.forEach(({ correspond, name }) => {
        if (correspond && name) {
          result.set(name, correspond)
        }
      })
      return result
    },
    feedbackBasicSizes() {
      const { currentSizeList } = this
      if (!currentSizeList.length) {
        return []
      }
      const one = currentSizeList[0].attr_value_name
      for (let i = 0; i < OriginSizes.length; i++) {
        if (OriginSizes[i].includes(one)) {
          return OriginSizes[i]
        }
      }
      return []
    },
    // 当前产品缺少的尺码
    // * 顺序固定
    absentSizes() {
      const { feedbackBasicSizes, currentSizeList } = this
      if (!feedbackBasicSizes.length) {
        return []
      }
      const sizeSet = new Set()
      feedbackBasicSizes.forEach(size => {
        sizeSet.add(size)
      })
      currentSizeList.forEach(({ attr_value_name_en }) => {
        sizeSet.delete(attr_value_name_en)
      })
      return [...sizeSet]
    },
    /**
     * 展示的尺码反馈项
     * { key: string | text: string | type: number }
     */
    feedBackSizeList() {
      const {
        absentSizes,
        localSizeRuleMap,
        language
      } = this
      if (!absentSizes.length) {
        return []
      }
      const result = []
      absentSizes.forEach(defaultSize => {
        const localSize = localSizeRuleMap.get(defaultSize)
        let { size } = sortMainSubSize(defaultSize, localSize, this.defaultlocalsize)
        result.push({ text: size, key: defaultSize, type: 0 })
      })

      // 在头部插入一个小于当前尺码的选项
      let firstSize = result[0].text
      result.unshift({
        // Smaller than {0}
        text: template(firstSize, language.SHEIN_KEY_PC_22555),
        key: 'lt',
        type: 1
      })

      // 在尾部插入一个大于当前尺码的选项
      let lastSize = result[result.length - 1].text
      result.push({
        // Larger than {0}
        text: template(lastSize, language.SHEIN_KEY_PC_22556),
        key: 'gt',
        type: 1
      })
      return result
    },
    // 相似推荐文案
    similarRecommendText() {
      const { selectedSize, localSizeRuleMap, language } = this
      if (!selectedSize.key || selectedSize.type === 1) {
        return ''
      }
      const correspond = localSizeRuleMap.get(selectedSize.key) || ''
      const sizeText = `${selectedSize.key}${
        correspond ? `(${correspond})` : ''
      }`
      return template(sizeText, language.SHEIN_KEY_PC_22438)
    }
  },
  watch: {
    shouldShow: {
      handler(val) {
        if (val === true) {
          // 上报埋点
          const { goodsDetail } = this
          daEventCenter.triggerNotice({
            daId: '1-8-4-10',
            extraData: {
              goods_id: goodsDetail.goods_id
            }
          })
        }
      }
    },
    country: {
      handler(val) {
        this.selectedLocation = val
      },

      immediate: true
    }
  },
  methods: {
    ...mapActions(['updateExposedList']),
    // 尺码反馈按钮点击
    onSizeFeedbackBtnClick() {
      this.renderFeedbackDialog = true
      this.dialogShow = true
      this.showSimilarFlag = false
      // 上报埋点
      const { goodsDetail } = this
      daEventCenter.triggerNotice({
        daId: '1-8-4-9',
        extraData: {
          goods_id: goodsDetail.goods_id
        }
      })
    },
    // 地区选择展开按钮
    onOpenCountrySelect() {
      this.countryOptionOpen = true
      // 上报埋点
      daEventCenter.triggerNotice({
        daId: '1-8-4-11'
      })
    },
    // 选择地区
    onSelectCountry(selectCountry) {
      this.selectedLocation = selectCountry
      this.countryOptionOpen = false
      // 上报埋点
      daEventCenter.triggerNotice({
        daId: '1-8-4-12',
        extraData: {
          location: selectCountry
        }
      })
    },
    // 选择反馈尺码
    selectSize(size) {
      const { type, key } = size
      this.selectedSize = {
        key,
        type
      }
      // 上报埋点
      const { goodsDetail, getReportSizeValue } = this
      const reportSize = getReportSizeValue(key)
      daEventCenter.triggerNotice({
        daId: '1-8-4-13',
        extraData: {
          goods_id: goodsDetail.goods_id,
          size: reportSize
        }
      })
    },
    // 提交反馈
    handleSubmit() {
      const {
        goodsDetail,
        selectedLocation = '',
        selectedSize,
        getReportSizeValue,
        hasLocalSizeOptions
      } = this
      const { key } = selectedSize
      if (!this.selectedSize.key) {
        return
      }
      this.status = 'success'
      // 上报埋点
      daEventCenter.triggerNotice({
        daId: '1-8-4-14',
        extraData: {
          goods_id: goodsDetail.goods_id,
          location: hasLocalSizeOptions ? selectedLocation : 'Local',
          size: getReportSizeValue(key)
        }
      })
    },
    onClose() {
      if (!this.shouldShow) {
        return
      }

      if (this.status === 'success') {
        this.$nextTick(() => {
          this.status = 'ready'
          this.selectedSize = {
            key: '',
            type: -1
          }
        })
      }

      // 上报埋点
      const { status, showSimilarFlag } = this
      if (status === 'ready') {
        return daEventCenter.triggerNotice({
          daId: '1-8-4-16'
        })
      }
      if (!showSimilarFlag) {
        return daEventCenter.triggerNotice({
          daId: '1-8-4-17'
        })
      }
    },
    // 找相似
    handleShowSimilar() {
      const { selectedSize, goodsDetail } = this
      const { key } = selectedSize
      SimilarPopover.showModal(this.goodsDetail, {
        similarFrom: 'out_of_stock',
        filterSize: key,
        updateExposedList: this.updateExposedList
      })
      this.dialogShow = false
      this.showSimilarFlag = true
      // 上报埋点
      daEventCenter.triggerNotice({
        daId: '1-8-4-15',
        extraData: {
          goods_id: goodsDetail.goods_id,
          size: key
        }
      })
    },

    // 获取埋点数据中size参数值
    getReportSizeValue(key) {
      const { localSizeRuleMap } = this
      if (!key) {
        return ''
      } else if (key === 'lt') {
        return '1'
      } else if (key === 'gt') {
        return '2'
      }

      const correspond = localSizeRuleMap.get(key) || ''
      return `${key}${correspond ? `(${correspond})` : ''}`
    },
    onChangeSelectCountry(value) {
      this.$emit('changeCountry', value)
    }
  },
  emits: ['changeCountry']
}
</script>

<style lang="less">
.size-feedback {
  position: relative;
  font-size: 12px;
  margin-bottom: 20px;
  &_title {
    color: #666;
    span {
      color: @sui_color_link;
      cursor: pointer;
    }
  }
}
.size-feedback_body {
  position: relative;
  overflow: visible;
  .tips-band {
    position: relative;
    left: -48px;
    width: calc(100% + 96px);
    padding: 12px 48px;
    font-size: 14px;
    line-height: 2;
    text-align: center;
    background-color: #f6f6f6;
    color: #767676;
    margin-bottom: 32px;
  }

  .local-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
    &__container {
      display: inline-flex;
      align-items: center;
      margin-bottom: 20px;
    }

    & > div:first-child {
      color: #222;
      font-size: 16px;
      font-weight: 700;
    }
    span {
      font-weight: 400;
    }
  }
}
.country-select_box {
  border: 1px solid #e5e5e5;
  color: #222;
  position: relative;
  font-size: 12px;
  div {
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: 12px;
    cursor: pointer;
  }

  i.svgicon-down_222 {
    margin-left: 10px;

    &.rotate-up {
      transform: rotate(180deg);
    }
  }

  .option-box {
    position: absolute;
    right: 0;
    top: calc(100% + 8px);
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(242, 242, 242, 1);
    padding: 5px 0;
    max-height: 172px;
    overflow-y: scroll;

    li {
      width: 138px;
      height: 36px;
      line-height: 36px;
      .padding-l(16px);
      cursor: pointer;

      &:hover,
      &.active {
        background: rgba(245, 244, 245, 1);
      }
    }
  }
}
.size-option_box {
  min-height: 32px;
  display: flex;
  flex-wrap: wrap;
  li {
    border: 1px solid @sui_color_gray_weak1;
    height: 32px;
    border-radius: 16px;
    text-align: center;
    margin-bottom: 12px;
    margin-right: 12px;
    cursor: pointer;

    &.active {
      border-color: #222;
    }

    .option-text {
      .text-overflow();
      font-size: 14px;
      padding: 0 12px;
      line-height: 32px;
    }
  }
}
.size-option_box.romwe {
  li:hover {
    border-color: #fc4070;
  }
  li.active {
    color: #fc4070;
    border-color: #fc4070;
  }
}
.size-feedback_success-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 310px;
  .sui_icon_success_96px {
    font-size: 96px;
    line-height: 1;
    color: #198055;

    &.romwe {
      color: #2bcacc;
    }
    &.romwe:before {
      content: "\e7d9";
    }
  }

  h2 {
    font-size: 16px;
    line-height: 20px;
    margin-top: 24px;
    color: #222;
    font-weight: 700;
  }

  h3 {
    font-size: 14px;
    color: #222;
    line-height: 18px;
    margin-top: 16px;
    font-weight: 400;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 48px 0 20px;
  }
} /*// 模态框修正*/
.size-feedback_footer {
  padding: 32px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.size-feedback_dialog {
  .sui-dialog__title-wrap {
    padding: 32px 48px 16px;
    .sui-dialog__title {
      font-size: 16px;
    }
  }

  .sui-dialog__wrapper > .sui-dialog__body {
    overflow: visible;
  }

  &.ready-state {
    .sui-dialog__body {
      margin-bottom: 0;
      height: unset !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  &.success-state {
    .sui-dialog__body {
      margin-top: 36px !important; /* stylelint-disable-line declaration-no-important */
      height: unset !important; /* stylelint-disable-line declaration-no-important */
      margin-bottom: 36px;
    }
  }
}
</style>

<template>
  <div
    class="size-findSimilar__container"
    :class="{
      'size-findSimilar__container-modal': mode === 'modal'
    }"
    @click.stop
  >
    <div class="size-findSimilar__content">
      <div
        v-tap="{
          id: '1-6-1-154',
          data: {
            similar_type: filterSize ? 'sku' : 'skc'
          }
        }"
        class="size-findSimilar__head"
        @click="showSimilarModal"
      >
        <div
          class="size-findSimilar__head-title"
          v-html="title"
        ></div>
        <div
          v-if="showAvatarList"
          class="size-findSimilar__head-icons"
        >
          <img
            v-for="(item, index) in products.slice(0, 3)"
            :key="index"
            :src="transformImg({ img: item.goods_img }) || ''"
            :alt="item.goods_name"
            class="size-findSimilar__head-icon"
          />
        </div>
        <div
          v-if="hasMoreProducts"
          class="size-findSimilar__head-right"
        >
          {{ language?.SHEIN_KEY_PC_26272 }}
          <Icon
            class="icon"
            name="sui_icon_more_right_12px_1"
            size="12px"
            :is-rotate="GB_cssRight"
          />
        </div>
      </div>
      <div
        class="size-findSimilar__swiper"
        :style="{
          maxHeight: (showList ? swiperHeight : 0) + 'px'
        }"
      >
        <div
          ref="swiperWrapper"
          class="s-swiper-container size-findSimilar__list j-da-event-box"
          :da-expose-code="exposeCode"
          data-poskey="similaritemsnew"
          data-module="module"
        >
          <div
            v-show="activeIndex !== 0"
            class="common-swiperv2__prev"
            @click="handleArrowBtn('left')"
          >
            <Icon
              class="icon"
              name="sui_icon_more_left_14px_1"
              size="14px"
              :is-rotate="GB_cssRight"
            />
          </div>
          <swiper-container 
            ref="newSwiper"
            init="false" 
            class="s-swiper-wrapper"
          >
            <swiper-slide
              v-for="(item, index) in products"
              :key="index"
              class="s-swiper-slide size-findSimilar__list-item"
            >
              <s-product-item
                ref="goodsItem"
                :index="index"
                :item="item"
                :language="language"
                :config="itemConfig"
                :bottom-info-style="bottomInfoStyle"
                :goods-item-info="goodsItemInfo"
                :constant-data="constantData"
                @click-add-to-bag="productClick"
                @open-quick-add="productClick"
                @click-item="productClick"
              />
              <img 
                v-show="loading"
                style="width:500%;height:500%;"
                :src="constantData.LAZY_IMG_SOLID_COLOR" 
              />
            </swiper-slide>
          </swiper-container>
          <div
            v-show="!swiperInstance?.isEnd"
            class="common-swiperv2__next"
            @click="handleArrowBtn('right')"
          >
            <Icon
              class="icon"
              name="sui_icon_more_right_14px_1"
              size="14px"
              :is-rotate="GB_cssRight"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import SProductItem from 'public/src/pages/components/productItem/ProductItem.vue'
import i18n from 'public/src/pages/common/biz_helper/i18n'
import { transformImg, template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'
import { Icon } from '@shein-aidc/icon-vue3'
import { extendsComplianceModeConfig } from 'public/src/pages/goods_detail_v2/utils/recommendConfigExtends.js'
import { register } from 'swiper/element'
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()

const daEventExpose = daEventCenter.getExposeInstance()
let QuickViewPopover = {},
    SimilarPopover = {}
if (typeof window !== 'undefined') {
  import('public/src/pages/common/quickView').then(res => {
    QuickViewPopover = res.default
  })
  import('public/src/pages/common/similar_products').then(res => {
    SimilarPopover = res.default
  })
}

const { IS_RW, GB_cssRight } = gbCommonInfo

export default {
  name: 'ProductFindSrimilarFloatContent',
  components: {
    SProductItem,
    Icon,
  },
  props: {
    isQuickAdd: {
      type: Boolean,
      default: false,
    },
    hasMoreProducts: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Number,
      default: 0,
    },
    productIntroData: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    skuInfo: {
      type: Object,
      default: () => ({}),
    },
    products: {
      type: Array,
      default: () => ([]),
    },
    filterSize: {
      type: String,
      default: '',
    },
    from: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    updateExposedList: {
      type: Function,
      default: () => {}
    },
    autoGetCouponAtAddBag: {
      type: Function,
      default: () => {}
    },
    showLoginModal: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      swiper: null,
      show: true,
      exposeCode: 'findSimilarFloatLayer',
      initTop: 0,
      IS_RW,
      GB_cssRight,
      showList: false,
      quickAddState: false,
      goodsItemInfo: null,
      itemConfig: {
        daEventExposeId: '2-3-2',
        hideGoodsName: true,
        hideOperationBtn: true,
        hidePromotionIcon: true,
        hideDiscountBadge: true, // 隐藏默认的折扣标签
        hideRetailPrice: true, // 隐藏划线价
        showDiscountLabelAfterPrice: true, // 折扣展示在价格右边
        showAddBagAfterGoodsPrice: true, // 加入购物车按钮展示在价格右边
        disableMainimgJump: true, // 禁止点击主图跳转
      },
      similarModalStatus: false,
      showAvatar: false,
      activeIndex: 0,
      swiperInstance: null
    }
  },
  computed: {
    constantData() {
      const { IMG_LINK, LAZY_IMG, LAZY_IMG_SQUARE, LAZY_IMG_SOLID_COLOR, GB_cssRight, IS_RW } = typeof window === 'undefined' ? this.locals || {} : gbCommonInfo
      return {
        IMG_LINK, 
        LAZY_IMG, 
        LAZY_IMG_SQUARE, 
        LAZY_IMG_SOLID_COLOR,
        GB_cssRight, 
        IS_RW
      }
    },
    skuSaleAttr() {
      return this.skuInfo?.sku_sale_attr || []
    },
    sizeText() {
      return this.filterSize && this.skuSaleAttr?.find((item) => item?.attr_id == 87)?.attr_value_name || ''
    },
    title() {
      let str = ''
      if (this.mode === 'modal' && this.language?.SHEIN_KEY_PC_26552) str += `${this.language?.SHEIN_KEY_PC_26552} `
      str += this.sizeText ? this.language?.SHEIN_KEY_PC_26244?.replace?.('\'{0}\'', `<span class='size-findSimilar__head-title_sku'>'${this.sizeText}'</span>`) : this.language?.SHEIN_KEY_PC_26243
      return str
    },
    bottomInfoStyle() {
      const result = {
        minHeight: '0px',
      }
      if (this.mode === 'module') {
        result.display = 'none'
      }
      return result
    },
    swiperHeight() {
      return this.$refs['swiperWrapper']?.offsetHeight || 500
    },
    showAvatarList() {
      return this.showAvatar && this.mode === 'modal' && !this.showList  && this.products?.length >= 3
    }
  },
  watch: {
    showList: {
      handler(val) {
        this.showAvatar = !val
        this.$emit('handle-list', !!val)
      },
    },
    update: {
      handler() {
        if (typeof window === 'undefined') return
        daEventExpose?.reset?.(this.exposeCode)
        this.offEvent()
        this.init()
      },
      immediate: true,
    },
    loading(val) {
      if (val) {
        this.swiper?.slideTo?.(0, 0)
      }
    },
  },
  mounted() {
    daEventExpose?.subscribe?.({
      keycode: `${this.exposeCode}\`2-3-2`,
      type: 'list',
    })
  },
  unmounted() {
    this.offEvent()
  },
  emits: ['handle-list'],
  inject: ['hide'],
  methods: {
    handleArrowBtn(direction) {
      if (direction === 'left') {
        this.swiperInstance.slidePrev()
      } else if (direction === 'right') {
        this.swiperInstance.slideNext()
      }
    },
    async init() {
      if (this.mode === 'modal') {
        this.goodsItemInfo = new GoodsItemInfo({
          listLanguage: await i18n.loadByQueue('item')
        })
        await this.goodsItemInfo.getProductInfo({
          goods: this.products,
          requestFields: {
            prices: true,
            promotion: true,
          }
        })
        this.itemConfig.configReady = true
        this.productIntroData?.apolloConfig?.isHitComplianceMode && extendsComplianceModeConfig(this.itemConfig)
      }
      // 首次执行时，需要等待dom渲染完成
      this.$nextTick(() => {
        this.initSwiper()
        this.handleList(true)
        this.initTop = document.body?.getBoundingClientRect?.()?.top || 0

        window.addEventListener('click', this.handleClick)
        window.addEventListener('scroll', this.handleScroll)
        this.$refs['goodsItem']?.forEach?.((item) => {
          if (!item) return
          item.setElAttr?.()
          item.$refs?.['imgcontainer']?.$el?.setAttribute?.('data-find-similar-mode', this.filterSize ? 'popup_similar_sku' : 'popup_similar_skc')
        })
      })
    },
    showSimilarModal(config = {}) {
      SimilarPopover.showModal(this.productIntroData.detail, {
        isFromDetailFloat: true,
        filterSize: this.filterSize || '',
        extendTitle: this.sizeText ? this.language?.SHEIN_KEY_PC_26245?.replace?.('\'{0}\'', `<span class='size-findSimilar__head-title_sku'>'${this.sizeText}'</span>`) : '',
        similarFrom: 'out_of_stock',
        statusCb: (val) => {
          const time = setTimeout(() => {
            clearTimeout(time)
            this.similarModalStatus = val
          })
        },
        recAnalysis: {
          activeFrom: 'popup_similar',
        },
        exAnalysis: {
          activeFrom: 'popup_similar',
        },
        recAdp: this.products.map(item => item?.goods_id).join(',') || '',
        recPoskey: 'similaritemsnew',
        updateExposedList: this.updateExposedList,
        ...config,
      })
      // 加车打开similar页面，要关闭加车防止套娃，组件嵌套过深沿用之前provider的hide方法
      if (this.isQuickAdd && this.hide) {
        this.hide()
      }
    },
    template,
    transformImg,
    offEvent() {
      window.removeEventListener('click', this.handleClick)
      window.removeEventListener('scroll', this.handleScroll)

    },
    handleClick() {
      if (this.quickAddState || this.similarModalStatus || this.mode === 'module') return
      this.handleList(false)
      this.offEvent()
    },
    handleScroll() {
      if (this.quickAddState || this.similarModalStatus || this.mode === 'module') return
      const currentTop = document.body?.getBoundingClientRect?.()?.top || 0
      // 滚动超过100px关闭推荐弹窗
      if (Math.abs(currentTop - this.initTop) > 100) {
        this.handleList(false)
        this.offEvent()
      }
    },
    productClick(product) {
      let self = this
      const { target } = product || {}
      if (product?.goods_id) {
        daEventCenter.triggerNotice({
          daId: '2-3-1',
          target,
        })
      }
      if (this.isQuickAdd) {
        this.jumpProductDetail(product)
        return
      }
      const goodsId = product?.goods_id || product?.item?.goods_id || ''
      const mainGoodsId = this.productIntroData?.detail?.goods_id
      this.quickAddState = true
      goodsId && QuickViewPopover.show({
        goodsId,
        mainGoodsId,
        callback: {
          closeQuickView: () => {
            const time = setTimeout(() => {
              clearTimeout(time)
              this.quickAddState = false
            })
          },
          handleOpenLogin: (addToWish) => {
            QuickViewPopover.hide()
            if (this.showLoginModal) {
              this.showLoginModal({
                type: 'qv-add-wish',
                originRef: {
                  addToWish
                }
              })
            }
          },
          handleAddToCartDone(res){
            window.cart_module && window.cart_module.recart(res)
            self.autoGetCouponAtAddBag({ needToast: res?.code == 0, isMainGoods: mainGoodsId == goodsId })
          },
        },
        analysis: {
          sourceTarget: target,
          sa: {
            activity_from: 'popup_similar',
          }
        }
      })
    },
    jumpProductDetail(product) {
      if(typeof SHEIN_W === 'undefined') return
      let link = ''
      const { goods_url_name, goods_id, cat_id, mall_code } = product
      link = getGoodsUrl(goods_url_name, goods_id, cat_id) + `?mallCode=${mall_code}`
      location.href = link
    },
    initSwiper() {
      const swiperEl = this.$refs.newSwiper
      if (!swiperEl) return

      const swiperParams = {
        slidesPerGroup: 5,
        observer: true,
        slidesPerView: 'auto',
        spaceBetween: this.mode === 'modal' ? 10 : 4,
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper,
        on: {
          slideChangeTransitionEnd: (swiper) => {
            this.activeIndex = swiper.realIndex
          }
        }
      }
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      this.swiperInstance = swiperEl.swiper
    },
    handleList(boo) {
      this.showList = !!boo
    },
  },
}
</script>
    
<style lang="less">
.size-findSimilar {
  .s-swiper-container{
    position: relative
  }
  &__container {
    line-height: 1;
    overflow: hidden;

    .common-swiperv2__prev,
    .common-swiperv2__next {
      width: 22px;
      height: 22px;
      box-shadow: -3px 0px 3px 0px rgba(0, 0, 0, 0.18);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: #FFF;
      margin-top: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .common-swiperv2__prev {
      left: 4px;
    }
    .common-swiperv2__next {
      right: 4px;
    }

    .swiper-button-disabled {
      display: none;
    }
  }

  &__content {
    padding: 10px;
    background-color: #F6F6F6;
  }

  &__head {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__head-title {
    color: #222;
    font-size: 12px;
    margin-right: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;

    &_sku {
      color: @sui_color_highlight;
      font-weight: bold;
    }
  }

  &__head-icons {
    display: flex;
    align-items: center;
  }

  &__head-icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 4px;
    object-fit: cover;
  }

  &__head-right {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    color: #222;
    font-size: 10px;
    white-space: nowrap;
    cursor: pointer;
  }

  &__swiper {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
  }

  &__list {
    padding-top: 6px;
  }

  &__list &__list-item {
    width: 50px;

    & > img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  &__container-modal {
    .size-findSimilar__content {
      padding: 24px 0;
      background-color: #FFF;
    }

    .size-findSimilar__head-title {
      color: #222;
      font-size: 16px;
      font-weight: 700;
    }

    .size-findSimilar__head-right {
      font-size: 13px;
    }

    .size-findSimilar__list {
      padding-top: 16px;
    }

    .size-findSimilar__list-item {
      width: 140px;
    }

    .common-swiperv2__prev,
    .common-swiperv2__next {
      width: 36px;
      height: 36px;
    }

    .common-swiperv2__prev {
      left: 10px;
    }
    .common-swiperv2__next {
      right: 10px;
    }
  }
}
</style>
    
  

<template>
  <div 
    v-show="quickShipSkuList && quickShipSkuList.length" 
    class="goodsd-sizes__quickship"
  >
    <span
      v-if="!deliveryFloorQuickshipTitle"
      class="title"
      :class="{grey: text.status != 1}"
    >{{ language.SHEIN_KEY_PC_23485 }}</span>
    <ClientOnly>
      <span 
        v-expose="{
          id: '1-8-6-103',
          data: exposeData
        }"
        v-html="text.p"
      ></span>
    </ClientOnly>
  </div>
</template>
<script>
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { template } from '@shein/common-function'
import { ClientOnly } from '@sheinfe/vue-client-only'
export default {
  directives: { expose },
  components: { ClientOnly },
  props: {
    mallCode: {
      type: String,
      default: ''
    },
    skuInfo: {
      type: Object,
      default: () => ({})
    },
    skuList: {
      type: Array,
      default: () => []
    },
    skcSaleAttr: {
      type: Array,
      default: () => []
    },
    localSizeMap: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    deliveryFloorQuickshipTitle: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: ''
    },
    newOpen: {
      type: Boolean,
      default: false
    },
    isNewSizeLocal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSingleSaleAttr () {
      return this.skcSaleAttr.length == 1
    },
    quickShipSkuList () {
      return this.skuList?.filter?.(sku => {
        return sku?.mall?.[this.mallCode]?.sku_quick_ship
      })
    },
    saleAttrName () {
      const attrNameList = this.skcSaleAttr.map(item => {
        return item.attr_name
      })
      return attrNameList.join(' / ')
    },
    exposeData () {
      return {
        location: this.from == 'detail' ? 'detail' : 'popup',
        status: this.text.status
      }
    },
    text () {
      if (this.quickShipSkuList.length) {
        if (this.skuList.length == 1) {
          return {
            p: this.calcQuickshipText({ zeroValue: '', langV1: this.language.SHEIN_KEY_PC_23520, langV2: this.language.SHEIN_KEY_PC_30661 }),
            status: 1
          }
        }
        if (this.skuInfo.sku_code) {
          // 选中了sku
          const attrValueList = this.skcSaleAttr.map(item => {
            const skuAttrItem = this.skuInfo.sku_sale_attr.find(_ => _.attr_id == item.attr_id)
            const attrValueItem = item.attr_value_list.find(_ => _.attr_value_id == skuAttrItem.attr_value_id)
            return this.calcSize(attrValueItem)
          })
          const langV1 = this.language[this.skuInfo?.mall?.[this.mallCode]?.sku_quick_ship ? 'SHEIN_KEY_PC_23518' : 'SHEIN_KEY_PC_23519']
          const langV2 = this.language[this.skuInfo?.mall?.[this.mallCode]?.sku_quick_ship ? 'SHEIN_KEY_PC_30659' : 'SHEIN_KEY_PC_30660']
          return {
            p: this.calcQuickshipText({ zeroValue: `<b>${attrValueList.join(' / ')}</b>`, langV1, langV2 }),
            status: this.skuInfo?.mall?.[this.mallCode]?.sku_quick_ship ? 1 : 3
          }
        } else {
          // 没选中sku
          if (this.skuList.length == this.quickShipSkuList.length) {
            // 全部支持快速发货
            return {
              p: this.calcQuickshipText({ zeroValue: this.saleAttrName, langV1: this.language.SHEIN_KEY_PC_23515, langV2: this.language.SHEIN_KEY_PC_30654 }),
              status: 1
            }
          } else {
            let p = ''
            if (this.isSingleSaleAttr && this.quickShipSkuList.length <= 3){
              const attrValueList = this.quickShipSkuList.map(item => {
                const attrValueId = item.sku_sale_attr[0].attr_value_id
                const attrValueItem = this.skcSaleAttr[0].attr_value_list.find(_ => _.attr_value_id == attrValueId)
                return this.calcSize(attrValueItem)
              })
              const langV1 =  this.language[this.quickShipSkuList.length == 1 ? 'SHEIN_KEY_PC_23694' : 'SHEIN_KEY_PC_23516']
              const langV2 =  this.language[this.quickShipSkuList.length == 1 ? 'SHEIN_KEY_PC_30655' : 'SHEIN_KEY_PC_30657']
              p = this.calcQuickshipText({ zeroValue: attrValueList.join(', '), langV1, langV2 })
            } else {
              p = this.calcQuickshipText({ zeroValue: this.saleAttrName, langV1: this.language.SHEIN_KEY_PC_23517, langV2: this.language.SHEIN_KEY_PC_30658 })
            }
            return {
              p, 
              status: 1
            }
          }
        }
      }
      return {
        p: '',
        status: 0
      }
    }
  },
  watch: {
    text: {
      immediate: true,
      handler () {
        this.$emit('updateQuickShip', this.text.status)
      }
    },
    newOpen () {
      this.$emit('updateQuickShip', this.text.status)
    }
  },
  methods: {
    template,
    calcSize (sizeItem) {
      let str = ''
      if (sizeItem.attr_std_value) {
        str += `${sizeItem.attr_std_value} / `
      }
      str += sizeItem.attr_value_name
      // 兼容重构前数据格式 => 加车弹窗接入新size组件后删除
      const correspond = sizeItem.correspond || this.localSizeMap?.[sizeItem.attr_value_name_en] || ''
      if (correspond) {
        return this.isNewSizeLocal  ? `${correspond} (${str})` : `${str} (${correspond})`
      }
      return str
    },
    calcQuickshipText({ zeroValue = '', langV1, langV2, }) { // 处理quickship强化文案
      if(this.deliveryFloorQuickshipTitle) { // 命中强化实验 返回新文案
        const quickshipTextDom = `<span class="text-green">${this.deliveryFloorQuickshipTitle}</span>`
        return zeroValue ? this.template(zeroValue, quickshipTextDom, langV2) : this.template(quickshipTextDom, langV2)
      }
      return this.template(zeroValue, langV1)  // 返回旧文案
    },
  },
  emits: ['updateQuickShip']
}
</script>
<style lang="less">
.goodsd-sizes{
  &__quickship {
    color: @sui_color_gray_dark2;
    font-size: 12px;
    line-height: 18px;
    overflow: hidden;
    margin: 0 0 25px;
    min-height: 18px;
    b {
      font-weight: bold;
      color: @sui_color_gray_dark1;
    }
    .title {
      float: left;
      color: @sui_color_safety;
      padding: 0 4px;
      margin-right: 4px;
    }
    .grey {
      color: @sui_color_gray_light1;
      background: @sui_color_gray_weak2;
    }
    .text-green {
      color: @sui_color_success;
    }
  }
}
</style>
